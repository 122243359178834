import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import type { FC, ReactNode } from 'react';
import type { User } from 'src/types/user';
import SplashScreen from 'src/components/SplashScreen';
import firebase from 'src/lib/firebase';
import { getAuth, createUserWithEmailAndPassword as FBcreateUserWithEmailAndPassword, GoogleAuthProvider, FacebookAuthProvider, signInWithPopup, signInWithEmailAndPassword as FBsignInWithEmailAndPassword, signOut, onAuthStateChanged } from "firebase/auth";
import { doc, setDoc, increment, collection, updateDoc, onSnapshot, getDoc } from 'firebase/firestore';
import { db } from 'src/lib/firebase-db';
import { zeroPad } from 'src/utils';

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

interface AuthContextValue extends AuthState {
  method: 'FirebaseAuth',
  createUserWithEmailAndPassword: (email: string, password: string, firstName: string, lastName: string) => Promise<any>;
  signInWithEmailAndPassword: (email: string, password: string) => Promise<any>;
  signInWithGoogle: () => Promise<any>;
  signInWithFacebook: () => Promise<any>;
  logout: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type AuthStateChangedAction = {
  type: 'AUTH_STATE_CHANGED';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type Action = AuthStateChangedAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'AUTH_STATE_CHANGED': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'FirebaseAuth',
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  signInWithFacebook: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const auth = getAuth();

  const signInWithEmailAndPassword = (email: string, password: string): Promise<any> => {
    return FBsignInWithEmailAndPassword(auth, email, password);
  };

  const signInWithGoogle = (): Promise<any> => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider)
  };

  const signInWithFacebook = (): Promise<any> => {
    const provider = new FacebookAuthProvider();
    return signInWithPopup(auth, provider)
  };

  const createUserWithEmailAndPassword = async (email: string, password: string, firstName: string, lastName: string): Promise<any> => {
    return FBcreateUserWithEmailAndPassword(auth, email, password).then((result) => {
      saveNewUser(result.user, firstName, lastName)
    });
  };

  const saveNewUser = async (user: any, firstName: string, lastName: string): Promise<any> => {
    // const ecgId = `ECG${zeroPad(increment(1).isEqual, 7)}`;
    // const counter = await updateDoc(doc(db, "users", "--stats--"), { count: increment(1) })
    // const unsub = onSnapshot(doc(db, "users", "--stats--"), (doc) => {
    //   console.log("Current data: ", doc.data());
    // });
    // console.log("🚀 ~ file: FirebaseAuthContext.tsx ~ line 108 ~ unsub ~ unsub", unsub)
    // console.log("🚀 ~ file: FirebaseAuthContext.tsx ~ line 105 ~ saveNewUser ~ counter", counter)
    // console.log("🚀 ~ file: FirebaseAuthContext.tsx ~ line 104 ~ saveNewUser ~ ecgId", ecgId)
    return await setDoc(doc(db, "users", user.uid), {
      // ecgId,
      email: user.email,
      firstName,
      lastName,
    });
  }

  const logout = (): Promise<void> => {
    return signOut(auth).then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Here you should extract the complete user profile to make it available in your entire app.
        // The auth state only provides basic information.

        const docSnap = await getDoc(doc(db, "users", user.uid));
        if (docSnap.exists()) {
          user = { ...user, ...docSnap.data()}
        }

        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: true,
            user: {
              id: user.uid,
              avatar: user.photoURL,
              email: user.email,
              ...user
            }
          }
        });
      } else {
        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    });

    return unsubscribe;
  }, [dispatch]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'FirebaseAuth',
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        signInWithGoogle,
        signInWithFacebook,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
