import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { doc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from 'src/lib/firebase-db';
import { useSnackbar } from 'notistack';

export interface FullNameModalProps {
  uid: string;
  email: string;
}
const FullNameModal: React.FC<FullNameModalProps> = ({ uid, email }) => {
  const [open, setOpen] = React.useState(true);
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
  };

  const onSave = async () => {
    try {
      await setDoc(doc(db, 'users', uid), {
        email,
        firstName,
        lastName
      });
      enqueueSnackbar('Saved', { variant: 'success' });
      handleClose();
      window.location.reload();
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        <DialogTitle>Basic Information</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginBottom: 10 }}>
            Please enter your first name & your last name.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="firstName"
            label="First Name"
            fullWidth
            variant="outlined"
            onChange={event => setFirstName(event.target.value)}
          />
          <TextField
            margin="dense"
            id="lasttName"
            label="Last Name"
            fullWidth
            variant="outlined"
            onChange={event => setLastName(event.target.value)}
          />
        </DialogContent>
        <DialogActions style={{ paddingRight: 20, paddingBottom: 20 }}>
          <Button onClick={handleClose}>Sign Out</Button>
          <Button onClick={onSave} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FullNameModal;
